export const getFluidImageSrc = (imgObj: ImgObject) => {
  if (!imgObj || !imgObj.childImageSharp) {
    return (imgObj && imgObj.publicURL) || undefined
  }

  if (!imgObj.childImageSharp.fluid) {
    return imgObj.publicURL
  }

  return imgObj.childImageSharp.fluid && imgObj.childImageSharp.fluid.srcWebp
    ? imgObj.childImageSharp.fluid.srcWebp
    : imgObj.childImageSharp.fluid.src
}

export const getFixedImageSrc = (imgObj: ImgObject) => {
  if (!imgObj || !imgObj.childImageSharp) {
    return (imgObj && imgObj.publicURL) || undefined
  }

  if (!imgObj.childImageSharp.fixed) {
    return imgObj.publicURL
  }

  return imgObj.childImageSharp.fixed && imgObj.childImageSharp.fixed.srcWebp
    ? imgObj.childImageSharp.fixed.srcWebp
    : imgObj.childImageSharp.fixed.src
}

type ImgObject = {
  publicURL?: string

  childImageSharp?: {
    fluid?: {
      base64: string
      aspectRatio: number
      src: string
      srcSet: string
      sizes: string
      srcWebp: string
    }
    fixed?: {
      base64: string
      width: number
      height: number
      src: string
      srcSet: string
      srcWebp: string
    }
  }
}
