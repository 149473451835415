import React, { Fragment, useRef } from "react"

interface Props {
  backgroundImageUrl: string
  videoName?: string
  videoNameMobile?: string
}

export const Header: React.FC<Props> = ({ backgroundImageUrl, videoName, videoNameMobile }) => {
  // const SHOW_VIDEO = process.env.NODE_ENV !== "development"
  const SHOW_VIDEO = true

  const videoDesktop = videoName ? require(`src/assets/videos/${videoName}`) : null
  const videoMobile = videoNameMobile ? require(`src/assets/videos/${videoNameMobile}`) : videoDesktop

  const videoMobileRef = useRef<HTMLVideoElement | null>(null)
  const [videoMobileIsPlaying, setVideoMobileIsPlaying] = React.useState(false)

  const toggleVideoState = () => {
    if (videoMobileRef.current) {
      if (videoMobileRef.current.paused) {
        videoMobileRef.current.play()
      } else {
        videoMobileRef.current.pause()
      }
    }
  }

  return (
    <div
      className={`header ${videoDesktop ? "header-fullheight" : ""}`}
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      {videoName && SHOW_VIDEO ? (
        <Fragment>
          {/* VIDEO DESKTOP */}
          <video className="header-video d-none d-lg-block" autoPlay loop muted poster={backgroundImageUrl}>
            <source src={videoDesktop} type="video/mp4" />
          </video>

          {/* VIDEO MOBILE */}
          <video
            className="header-video d-lg-none"
            autoPlay
            loop
            muted
            ref={videoMobileRef}
            onPlay={() => {
              console.log("playing")
              setVideoMobileIsPlaying(true)
            }}
            onPause={() => {
              console.log("paused")
              setVideoMobileIsPlaying(false)
            }}
            onClick={() => {
              toggleVideoState()
            }}
          >
            <source src={videoMobile} type="video/mp4" />
          </video>

          {/* video play icon visible only if video is paused */}
          <div
            className={`d-lg-none fade ${!videoMobileIsPlaying ? "show" : ""}`}
            onClick={() => {
              toggleVideoState()
            }}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              cursor: "pointer",
            }}
          >
            {iconPlay}
          </div>
        </Fragment>
      ) : null}
    </div>
  )
}

const iconPlay = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 366.56 366.56"
    xmlSpace="preserve"
    width="100"
    fill="#ffffff"
  >
    <path
      d="M295.312,179.832c-0.61-1.062-1.491-1.942-2.552-2.552l-79.36-46l-0.4-0.2l-78.96-45.4
			c-3.316-1.9-7.545-0.752-9.444,2.564c-0.599,1.046-0.915,2.23-0.916,3.436v183.24c0.022,3.813,3.107,6.898,6.92,6.92
			c1.434-0.002,2.831-0.449,4-1.28l78.8-45.28l79.36-46C296.074,187.376,297.216,183.146,295.312,179.832z"
    />
    <path
      d="M183.28,0C82.057,0,0,82.057,0,183.28s82.057,183.28,183.28,183.28s183.28-82.057,183.28-183.28S284.503,0,183.28,0z
			 M183.28,332.52c-82.423,0-149.24-66.817-149.24-149.24S100.857,34.04,183.28,34.04s149.24,66.817,149.24,149.24
			S265.703,332.52,183.28,332.52z"
    />
  </svg>
)
