import React from "react"
import showdown from "showdown"

interface Props {
  content: string
  isWrapper?: boolean
}

const converter = new showdown.Converter()

export const MarkdownContent: React.FC<Props> = ({ content, isWrapper }) => (
  <div
    className="md-content"
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }}
    style={{ display: isWrapper ? "contents" : undefined }}
  />
)
