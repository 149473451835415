import React from "react"
import { Container, Col, Row, Card, CardImg, CardTitle, CardBody, CardText, CardFooter, CardDeck } from "src/ui"
import { ImageData } from "ExtendiApp"
import { InternalLink } from "../InternalLink"
import useTranslations from "../useTranslations"
import { MarkdownContent } from "../MarkdownContent"

interface Props {
  blocks: Offer[]
}

type Offer = {
  title: string
  image: ImageData
  text: string
  date: string
}

export const OffersBlock: React.FC<Props> = ({ blocks }) => {
  const buttonText = useTranslations("more")
  return (
    <section className={"section section-default pt-0"}>
      <Container fluid className="p-0">
      <CardDeck>
          {blocks.map(({ title, text, image, date }, index) => (
              <Card className="mb-5" key={index}>
                <CardImg top width="100%" src={image.imageUrl} alt={image.altText} className="shadow-lg" />
                <CardBody>
                  <CardTitle>
                    <h4 className="mt-3">{title}</h4>
                    <p className="text-warning text-uppercase small">{date}</p>
                  </CardTitle>
                  <CardText>
                    <MarkdownContent content={text} />
                  </CardText>
                </CardBody>
                <CardFooter>
                  {/* pageId is the relative folder name path from /content */}
                  <InternalLink pageId="richieste-prenotazione/richiesta" className="btn btn-success btn-block">
                    {buttonText}
                  </InternalLink>
                </CardFooter>
              </Card>
          ))}
        </CardDeck>
      </Container>
    </section>
  )
}
