import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { PageIntro } from "src/components/PageIntro"
import { Header } from "src/components/Header"
import { getFluidImageSrc, getFixedImageSrc } from "src/utils/images"
import SEO from "src/components/SEO"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { OffersBlock } from "src/components/OffersBlock"

const OffersPage: React.FC<any> = ({ data: { mdx } }) => {
  if (!mdx.frontmatter || !mdx.frontmatter.title || !mdx.frontmatter.intro.title) {
    return <div>No content has been created for this page</div>
  }

  const {
    title: pageTitle,
    intro: { title, lead, content },
    headerImage,
    videoFileName,
    offerBlocks,
  } = mdx.frontmatter

  return (
    <Fragment>
      <SEO title={pageTitle} description={content} />

      {/* HEADER */}
      <Header backgroundImageUrl={getFluidImageSrc(headerImage) || ""} videoName={videoFileName} />

      {/* INTRO */}
      <PageIntro title={title} lead={lead}>
        {content && content.length ? content : <MDXRenderer>{mdx.body}</MDXRenderer>}
      </PageIntro>

      {/* OFFER BLOCKS */}
      <OffersBlock
        blocks={offerBlocks.map(({ offerName, offerImage, offerDescription, offerValid }) => ({
          title: offerName,
          image: {
            imageUrl: getFixedImageSrc(offerImage),
            altText: offerName,
          },
          text: offerDescription,
          date: offerValid,
        }))}
      />
    </Fragment>
  )
}

export default OffersPage

export const query = graphql`
  query OffersPage($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      body
      frontmatter {
        title
        intro {
          title
          lead
          content
        }
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
        videoFileName
        offerBlocks {
          offerName
          offerValid
          offerDescription
          offerImage {
            publicURL
            childImageSharp {
              fixed(width: 400, quality: 90) {
                ...GatsbyImageSharpFixed
                srcWebp
              }
            }
          }
        }
      }
    }
  }
`
